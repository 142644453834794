<template>
  <div>
    <LiefengContent>
      <template v-slot:title>{{ "网格党支部初始化拆分" }}</template>
      <template v-slot:toolsbarRight>
        <Form :label-colon="true" :inline="true">
            <Button type="info" :loading="table.btnLoading" style="margin-right: 10px" @click="reSetData">确定并创建网格</Button>
        </Form>
      </template>
      <template v-slot:contentArea>
        <LiefengTable
          :talbeColumns="table.talbeColumns"
          :tableData="table.tableData"
          :loading="table.loading"
          :fixTable="true"
          :curPage="table.page"
          :total="table.total"
          :pagesizeOpts="[20, 30, 50, 100]"
          :page-size="table.pageSize"
          @hadlePageSize="hadlePageSize"
        ></LiefengTable>
      </template> 
    </LiefengContent>
  </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3";
import LiefengTable from "@/components/LiefengTable";
export default {
  components: { LiefengContent, LiefengTable},
  data() {
    return {
      search:{
      },
      table: {
        talbeColumns: [
            {
                key:'buildingName',
                align:'center',
                title:'网格党支部名称',
                render: (h, params) => {
                    return h("div", [
                        h("Input", {
                            props: {
                                value: params.row.buildingName,
                            },
                            on: {
                                "on-change": e => {
                                    this.table.tableData.map(item => {
                                        if (item.id == params.row.id) {
                                            item.buildingName = e.target.value
                                        }
                                    })
                                },
                            },
                        }),
                    ])
                },
            },
            {
                key:'alias',
                align:'center',
                title:'网格简称',
                render: (h, params) => {
                    return h("div", [
                        h("Input", {
                            props: {
                                value: params.row.alias,
                            },
                            on: {
                                "on-change": e => {
                                    this.table.tableData.map(item => {
                                        if (item.id == params.row.id) {
                                            item.alias = e.target.value
                                        }
                                    })
                                },
                            },
                        }),
                    ])
                },
            },
            {
                title: "操作",
                align: "center",
                width: 300,
                render: (h, params) => {
                    return h("div", [
                        h(
                            "Button",
                            {
                                props: {
                                    type: "error",
                                    size: "small",
                                },
                                nativeOn:{
                                  click:() =>{
                                      this.table.tableData.some((item,index)=>{
                                        if(item.id == params.row.id){
                                          this.table.tableData.splice(index,1)
                                        }
                                      })
                                  }
                                }
                                
                            },
                            "删除"
                        ),
                    ])
                },
            },
         
        ],
        tableData: [],
        loading: false,
        page: 1,
        total: 0,
        pageSize: 20,
        currentPage:1,
        btnLoading:false
      },
     
    };
  },
  async created(){
    await this.getList()
  },
  methods:{
    // 切换分页事件
    hadlePageSize(val) {
      this.table.page = val.page;
      this.table.pageSize = val.pageSize;
      this.getList();
    },
    // 获取列表
    getList(){
      this.table.loading = true
      try{
        this.$get('/syaa/pc/sy/user/gridManage/queryPartyManageByPage',{
          importType:3,
          archivesId:'',
          orgCode:this.$route.query.orgCode,
          showNoSync:'1',
          page:this.table.page,
          pageSize:this.table.pageSize
        }).then(res=>{
          this.table.loading = false
          if(res && res.code == 200){
            let arr = []
            if(res.dataList && res.dataList.length != 0){
              arr = res.dataList.map( item => {
                return {
                  ...item,
                  alias:item.alias?item.alias:item.buildingName
                  
                }
              })
            }
            this.table.tableData = arr
            this.table.total = res.maxCount
            this.table.currentPage = res.currentPage
          }else{
              this.$Message.error({
                content:'获取数据失败',
                background:true
              })
              return
          }
        })
      }catch(e){
        this.table.loading = false
        this.$Message.error({
          content:'获取数据失败',
          background:true
        })
        return
      }
    },
    //确定并创建网格
    reSetData(){
      if(this.table.tableData.length == 0){
        this.$Message.warning({
            content:'暂无数据创建',
            background:true
        })
        return
      }
      for(let i=0;i<this.table.tableData.length;i++){
        if(!this.table.tableData[i].buildingName || !this.table.tableData[i].alias){
          
          this.$Message.warning({
              content:'请编辑完内容',
              background:true
          })
          return
        }
      }
      this.table.btnLoading = true
      try{
        this.$post('/syaa/pc/sy/user/gridManage/syncGridParty',{
          archivesGridImpVos:this.table.tableData,
          orgCode:this.$route.query.orgCode,
        },{"Context-Type":'Application/json'}
        ).then( res => {
           this.table.btnLoading = false
          if(res.code == 200){
            this.$Message.success({
              content:'确定并创建网格成功',
              background:true
            })
            this.table.page = this.table.currentPage
            this.getList()
          }else{
            this.$Message.error({
              content:res.desc,
              background:true
            })
            return
          }
          
        })
      }catch(e){
        this.table.btnLoading = false
        this.$Message.error({
          content:'确定并创建网格失败',
          background:true
        })
        return
      }
    }
  }
};
</script>
